.App {
  text-align: center;
  background-color: var(--background-color);
  height: 100vh;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

:root,
  :root {
  --select-color: #D14D72;
  --menu-color: #FFABAB;
  --background-color: #FEF2F4;
  --text-color: black;
}
  :root.dark {
  --select-color: #0d1321;
  --menu-color: #1d2d44;
  --background-color: #364559;
  --text-color: lightgrey;
}
  :root.old {
  --select-color: #A1002E;
  --menu-color: #D14D72;
  --background-color: #EB9FB5;
  --text-color: black;
}

.portrait {
  width: 210px;
  margin-bottom: 0;
  background-color: var(--background-color);
  display: inline-block;
}

.portrait-image {
  width: 200px;
  border-radius: 50%;
  border: 5px solid var(--select-color);
}

.portrait-label {
  margin-top: 0;
  text-align: center;
  display: inline-block;
  background-color: var(--menu-color);
}

.image {
  width: 200px;
}

.home-image {
  bottom: 0px;
  position: absolute;
  width: 600px;
  right: 2%;
}

.home-title-1 {
  top:5%;
  position: absolute;
  left: 20%;
  font-size: 7vw;
  font-weight: bolder;
  color:var(--text-color);
  font-family: "Lucida Console";
}
.home-title-2 {
  top:17%;
  position: absolute;
  left: 10%;
  font-size: 7vw;
  font-weight: bolder;
  color:var(--text-color);
  font-family: "Lucida Console";
}

.home-text {
  position: absolute;
  width: 400px;
  top: 80%;
  left: 20%;
}

.image-track {
  display: flex;
  gap: 4vmin;
  top: 25%;
  width: 70%;
  margin-left: 15%;
  margin-top: 5%;
}

.page-header {
  height: 5%;
  width: 100%;
  background-color: #fde7eb;
}

.home-header-item {
  display: inline-flex;
  width: fit-content;
  font-size: small;
  margin-left: -35%;
  margin-right: 35%;
}

.page-header-item {
  display: inline-flex;
  width: fit-content;
  font-size: small;
  margin-left: 10px;
  margin-right: 0;
}

.home-header-item-text {
  font-weight: 200;
  font-family: "Lucida Console";
}
.page-header-item-text {
  font-weight: 300;
}

.page-header-item-link:link {
  color: var(--text-color);
  text-decoration: underline;
  text-decoration-color: transparent;
  transition: 0.4s;
}

.page-header-item-link:hover {
  text-decoration-color: var(--text-color);
  
}

.page-header-item-link:visited {
  color: var(--text-color);
}

.expandable-button {
  cursor: pointer;
  font: inherit;
  background: transparent;
  border: none;
  color: var(--text-color);
  padding: 0;
  font-size: xx-large;
}

.expandable-button-item {
  display: inline-block;
  font-size: 1.5rem;
  margin-left: 0.25rem;
}

.expandable-button.expanded .expandable-button-item {
  transform: rotate(180deg);
}

.expandable-button-description {
  margin: 0;
  font-size:larger;
}

.about-page {
  margin-top: 10px;;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  border: 2px var(--select-color);
}

.about-page-items {
  width: 50%;
}
.about-title {
  color: var(--select-color);
}
.about-text {
  color: var(--select-color);
}

.canvas {
  background-color: white;
}

.animationRange {
  display: inline;
}



.project-link-container {
  background-color: var(--menu-color);
  border: 2px solid var(--select-color);
}

.project-link-title {
  background-color: var(--menu-color);
  margin: 5px;
}



.connect-four-game {
  margin-top: 50px;
}

.connect-four-column {
  display: inline-block;
}

.connect-four-item {
  width: 100px;
  height: 100px;
  border-radius: 50%;
}



#adventureGame {
  width: 100%;
  background-color: dimgray;
  display: inline-block;
}

#console {
  background-color: darkgray;
  padding: 1em;
  width: 40%;
  margin-left: 8em;
  margin-top: 2em;
  height: 40em;
  float: left
}
#consoleLog {
  height: 32em;
  background-color: darkgray;
  overflow: auto;
  float: left;
  white-space: pre-wrap;
}
#consolePrompt {
  height: 2em;
  width: 100%;
  margin-left: -3px;
  background-color: darkgray;
}

#world {
  background-color: darkgray;
  overflow: auto;
  float: right;
  width: 40%;
  margin-right: 8em;
  margin-top: 2em;
}
#location {
  background-color: darkgray;
  height: 20em;
  overflow: auto;
}

#inventory {
  margin-top: 2em;
  background-color: darkgray;
  float: right;
  width: 40%;
  margin-right: 8em;
}
#inventoryItems {
  background-color: darkgray;
  height: 15em;
  overflow: auto;
}


.resume-left {
  float: left;
  margin-left: 30%;
  width: 30%;
  text-align: left;
}
.resume-title {
  margin-top: 10px;
  margin-bottom: 5px;
  text-align: left;
}
.resume-subtitle {
  margin-top: 0px;
  margin-bottom: 0px;
  text-align: left;
  margin-left: 5%;
}
.resume-item {
  margin-left: 10%;
  margin-top: 0;
  margin-bottom: 0;
  text-align: left;
}
.resume-contact {
  margin: 0;
  text-align: left;
  margin-left: 30%;
}
.resume-name {
  margin: 0;
  text-align: left;
  margin-left: 30%;
}
.resume-right {
  float: right;
  margin-right: 10%;
  width: 30%;
  text-align: left;
}