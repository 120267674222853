/* -------- Main window layout -------- */

.dice-project {
    width: 100%;
    height: 100vh;
    position: fixed;
    overflow: hidden;
}

.dice-window {
    background-color: #fcdbe1;
    position: absolute;
    right: 0;
    top: 0;
    min-height: 100vh;
    height: auto;
    width: 100%;
    overflow-y: scroll;
    padding-bottom: 20px;
    padding-left: 50px;
}

.header-container {
    display: flex;
    align-items: center;
    gap: 20px;
    margin: 20px 20px 30px 100px;
    width: calc(100% - 120px);
    flex-shrink: 0;
    position: sticky;
    top: 0;
}

.dice-display-container {
    overflow-y: scroll;
    height: 80%;
}

.all-dice {
    grid-column: 2;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}

/* -------- Dice Set Editor -------- */

.dice-set-editor {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 30px;
    padding: 30px;
    height: calc(100vh - 90px);
    overflow: hidden;
    background-color: #fff5f7;
    transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
    transform-origin: center;
    will-change: transform, opacity;
}

.dice-set-editor-left {
    display: flex;
    flex-direction: column;
    gap: 15px;
    height: 100%;
    overflow: hidden;
    transition: transform 0.3s cubic-bezier(0.4, 0, 0.2, 1);
    will-change: transform;
}

.dice-set-editor-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 15px;
}

.dice-action-button.back {
    font-size: 24px;
    color: #4a5568;
    margin-left: 15px;
    z-index: 1000;
    position: relative;
}

.dice-set-editor-title {
    font-size: 24px;
    font-weight: bold;
    color: #2d3748;
    padding: 15px;
    border: none;
    border-radius: 12px;
    background-color: white;
    flex: 1;
    box-shadow: 0 2px 4px rgba(0,0,0,0.05);
    text-align: center;
    margin: 0;
}

.dice-set-editor-dice {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    padding: 25px;
    background-color: white;
    border-radius: 12px;
    flex: 1;
    overflow-y: auto;
    min-height: 0;
    box-shadow: 0 4px 6px rgba(0,0,0,0.05);
}

.dice-set-editor-right {
    background-color: white;
    border-radius: 12px;
    padding: 15px;
    box-shadow: 0 4px 6px rgba(0,0,0,0.05);
    height: calc(100% - 30px);
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    gap: 12px;
    transition: transform 0.3s cubic-bezier(0.4, 0, 0.2, 1);
    will-change: transform;
}

.dice-set-editor-section {
    padding: 10px;
    transition: all 0.2s ease;
}

.dice-set-editor-section:hover {
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
}

.dice-set-editor-section-title {
    font-size: 16px;
    font-weight: 600;
    color: #4a5568;
    margin-bottom: 10px;
    text-align: center;
    letter-spacing: 0.5px;
}

.dice-set-editor-preview {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px;
    border-radius: 12px;
    transition: all 0.2s ease;
    min-height: 100px;
    background-color: transparent;
}

.dice-set-editor-options {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    justify-content: center;
    padding: 10px;
    border-radius: 8px;
    min-height: 120px;
    align-items: center;
    background-color: transparent;
}

.dice-set-editor-buttons {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: auto;
}

.dice-set-editor-add-button, 
.dice-set-editor-cancel-button,
.dice-set-editor-delete-button {
    padding: 10px 20px;
    font-size: 14px;
    font-weight: 600;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: all 0.2s ease;
    width: 100%;
    text-transform: uppercase;
    letter-spacing: 1px;
    position: relative;
    overflow: hidden;
}

.dice-set-editor-add-button {
    background-color: #48bb78;
    color: white;
    box-shadow: 0 2px 4px rgba(72, 187, 120, 0.2);
    border: 1px solid rgba(72, 187, 120, 0.3);
}

.dice-set-editor-add-button:hover {
    background-color: #38a169;
    transform: translateY(-1px);
    box-shadow: 0 4px 8px rgba(72, 187, 120, 0.3);
    border-color: rgba(72, 187, 120, 0.5);
}

.dice-set-editor-cancel-button {
    background-color: #718096;
    color: white;
    box-shadow: 0 2px 4px rgba(113, 128, 150, 0.2);
    border: 1px solid rgba(113, 128, 150, 0.3);
}

.dice-set-editor-cancel-button:hover {
    background-color: #4a5568;
    transform: translateY(-1px);
    box-shadow: 0 4px 8px rgba(113, 128, 150, 0.3);
    border-color: rgba(113, 128, 150, 0.5);
}

.dice-set-editor-delete-button {
    background-color: #f56565;
    color: white;
    box-shadow: 0 2px 4px rgba(245, 101, 101, 0.2);
    border: 1px solid rgba(245, 101, 101, 0.3);
}

.dice-set-editor-delete-button:hover {
    background-color: #e53e3e;
    transform: translateY(-1px);
    box-shadow: 0 4px 8px rgba(245, 101, 101, 0.3);
    border-color: rgba(245, 101, 101, 0.5);
}

/* -------- Header bar -------- */

.header-container {
    display: flex;
    align-items: center;
    gap: 20px;
    margin-top: 50px;
    margin-left: 50px;
    width: calc(100% - 120px);
}

.dice-header {
    display: flex;
    gap: 20px;
    flex-shrink: 0;
    align-items: center;
    height: 60px;
}

.roll-button {
    height: 72px;
    padding: 0 28px;
    width: 150px;
    font-size: 18px;
    font-weight: 600;
    color: white;
    background-color: #48bb78;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: all 0.2s ease;
    text-transform: uppercase;
    letter-spacing: 1px;
    box-shadow: 0 2px 4px rgba(72,187,120,0.2);
}

.roll-button:hover {
    background-color: #38a169;
    transform: translateY(-1px);
    box-shadow: 0 4px 8px rgba(72,187,120,0.3);
}

.dice-button {
    height: 72px;
    padding: 0 28px;
    font-size: 16px;
    font-weight: 600;
    color: white;
    background-color: #718096;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: all 0.2s ease;
    text-transform: uppercase;
    letter-spacing: 1px;
    box-shadow: 0 2px 4px rgba(113,128,150,0.2);
    white-space: nowrap;
}

.dice-button:hover {
    background-color: #4a5568;
    transform: translateY(-1px);
    box-shadow: 0 4px 8px rgba(113,128,150,0.3);
}

.dice-action-button {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: none;
    background-color: #fde7eb;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.2s ease;
}

.dice-action-button:hover {
    background-color: #fcdbe1;
    transform: translateY(-1px);
}

.dice-action-button svg {
    width: 20px;
    height: 20px;
    fill: #333;
}

.dice-action-button.delete svg {
    fill: #dc3545;
}

.dice-action-button.edit svg {
    fill: #28a745;
}

/* Add Dice Set button in DiceSelector */
.add-dice-set-button {
    margin: 20px;
    padding: 14px 28px;
    font-size: 16px;
    font-weight: 600;
    color: white;
    background-color: #48bb78;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: all 0.2s ease;
    text-transform: uppercase;
    letter-spacing: 1px;
    box-shadow: 0 2px 4px rgba(72,187,120,0.2);
}

.add-dice-set-button:hover {
    background-color: #38a169;
    transform: translateY(-1px);
    box-shadow: 0 4px 8px rgba(72,187,120,0.3);
}

/* -------- Dice Box -------- */

.dice-changer {
    background-color:#61dafb;
    box-shadow: 5px 5px 10px black;
    display: grid;
    grid-template-rows: 25% 25% 25% 25%;
    grid-template-columns: 33% 34% 33%;
    margin-top: 5%;
    margin-left: 5%;
    width: 80%;
    height: 85%;
}
.dice-holder {
    margin-left: 3%;
    margin-top: 1%;
    padding-bottom: 3%;
    padding-left: 3%;
    padding-right: 3%;
    width: 30%;
    min-height: 75px;
    overflow: auto;
    border-radius: 25px;
    border: 3px solid transparent;
    transition: border-color 0.2s ease;
}

.dice-holder.selected {
    border-color: #48bb78;
}

.dice-holder-title {
    text-align: left;
    font-size: large;
    font-family: "Helvetica";
}

/* -------- Color Switches -------- */

.dice-color-switch {
    float: left; 
    align-items: center;
    margin: 10px;
    height: 70px;
    width: 70px;
}
.dice-color-input {
    background-color: transparent;
    margin: 5px;
    height: 60px;
    width: 60px;
    border: 0;
}

/* -------- Main Dice -------- */

.dice-display-window {
    display: grid;
    grid-template-columns: 150px 1fr;
    margin-top: 30px;
    margin-left: 50px;
    margin-right: 20px;
    padding-top: 5px;
    min-height: 105px;
    transition: all 0.2s ease;
    cursor: pointer;
    transform-origin: center;
    width: fit-content;
}

.dice-display-window:hover {
    transform: scale(1.02);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
    z-index: 1;
}

.dice-roll-info {
    grid-column: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2px;
}

.dice-total {
    background-color: #fde7eb;
    height: 90px;
    width: 150px;
    margin: 0;
    padding: 0;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    position: relative;
}

.dice-total-title {
    font-weight: bold;
    font-size: 4em;
    line-height: 1;
    font-family: "Helvetica";
    margin: 0 0 10px 0;
}

.dice-total-subtitle {
    font-size: 12px;
    font-weight: 500;
    font-family: "Helvetica";
    color: #4a5568;
    margin-bottom: 2px;
}

.dice-set-name {
    font-size: 12px;
    color: #4a5568;
    font-weight: 500;
    text-align: center;
    font-family: "Helvetica";
    margin-top: 10px;
    width: 100%;
    position: static;
}

.die-container {
    float: left;
    margin-left: 20px;
    margin-right: 0px;
    height: 90px;
    width: 90px;
    font-weight: bold;
    font-size: 3em;
    line-height: 90px;
    font-family: "Helvetica";
}
.die-container-small {
    float: left;
    margin-left: 3px;
    line-height: 50px;
    height: 50px;
    width: 50px;
}

.dice-4, .dice-6, .dice-8, .dice-10, .dice-12, .dice-20 {
    height: 90px;
    width: 90px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}
.dice-4.small, .dice-6.small, .dice-8.small, .dice-10.small, .dice-12.small, .dice-20.small {
    height: 50px;
    width: 50px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
}

.dice-4 {
    clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
    line-height: 120px;
}
.dice-6 {
    border-radius: 5%;
}
.dice-8 {
    clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
}
.dice-10 {
    clip-path: polygon(50% 0%, 100% 40%, 100% 65%, 50% 100%, 0 65%, 0 40%);
}
.dice-12 {
    clip-path: polygon(50% 0%, 80% 10%, 100% 35%, 100% 70%, 80% 90%, 50% 100%, 20% 90%, 0% 70%, 0% 35%, 20% 10%);
}
.dice-20 {
    clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
}
.oldDice {
    filter: opacity(0.4);
    transition: filter 0.2s ease;
}

.dice-display-window:hover .oldDice {
    filter: opacity(0.85);
}

/* -------- Dice Set Preview -------- */

.dice-set-preview {
    display: flex;
    align-items: center;
    transition: all 0.2s ease;
}

.dice-set-preview:hover {
    transform: translateY(-1px);
    box-shadow: 0 4px 8px rgba(252, 219, 225, 1);
}

.dice-set-preview-title {
    display: none;
}

.dice-set-preview-dice {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
    justify-content: center;
    align-items: center;
    padding: 8px 15px;
    background-color: #fde7eb;
    border-radius: 6px;
    height: 56px;
    min-height: unset;
}

/* -------- Mobile Styles -------- */
@media (max-width: 600px) {
    .dice-project {
        height: 100vh;
        overflow-y: auto;
    }

    .dice-window {
        padding-left: 5px;
        padding-right: 5px;
        width: 100%;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
    }

    .header-container {
        margin: 20px;
        width: calc(100% - 40px);
        flex-direction: column;
        align-items: flex-start;
        gap: 10px;
        position: fixed;
        top: 20px;
    }

    .dice-header {
        width: 100%;
        justify-content: center;
        flex-direction: column;
        order: 2;
        pointer-events: none;
        position: relative;
    }

    .dice-header button {
        pointer-events: auto;
    }

    .dice-set-preview {
        width: 100%;
        flex-direction: column;
        gap: 10px;
        order: 1;
    }

    .dice-set-preview-title {
        display: none;
    }

    .dice-set-preview-dice {
        width: 100%;
        height: fit-content;
        gap: 8px;
    }

    .roll-button {
        width: 100%;
        height: 50px;
        font-size: 18px;
    }

    .dice-display-container {
        padding: 0 20px;
        display: flex;
        flex-direction: column;
        overflow-y: scroll;
        gap: 120px;
        position: fixed;
        top: 240px;
    }

    .dice-color-switch {
        float: left; 
        align-items: center;
        margin: 5px;
        height: 70px;
        width: 70px;
    }
    .dice-color-input {
        background-color: transparent;
        margin: 5px;
        height: 60px;
        width: 60px;
        border: 0;
    }

    .dice-set-editor {
        grid-template-columns: 1fr;
        padding: 10px;
        height: auto;
        min-height: auto;
        gap: 15px;
        background-color: transparent;
        box-shadow: none;
    }

    .dice-set-editor-left {
        height: auto;
        width: 100%;
        background-color: transparent;
        box-shadow: none;
    }

    .dice-set-editor-dice {
        min-height: auto;
        gap: 5px;
        padding: 0px;
        background-color: transparent;
        box-shadow: none;
        width: 100%;
        box-sizing: border-box;
    }

    .dice-set-editor-right {
        height: auto;
        margin-top: 0px;
        gap: 0px;
        width: 100%;
        padding: 0px;
        background-color: transparent;
        box-shadow: none;
    }

    .dice-set-editor-section {
        background-color: transparent;
        border-radius: 8px;
        box-shadow: none;
        padding: 5px;
        gap: 0px;
        width: 100%;
        box-sizing: border-box;
    }

    .dice-set-editor-title {
        font-size: 20px;
        padding: 5px;
    }

    .dice-set-editor-header {
        flex-direction: column;
        align-items: stretch;
        gap: 10px;
        width: 100%;
    }

    .dice-action-button.back {
        font-size: 20px;
        margin-left: 0;
        margin-bottom: 10px;
        width: 100%;
        height: 50px;
        border-radius: 8px;
    }

    .dice-set-editor-section-title {
        display: none;
    }

    .dice-set-editor-preview {
        min-height: auto;
        padding: 10px;
        width: 100%;
        box-sizing: border-box;
        background-color: transparent;
        border-radius: 0;
        display: none;
    }

    .dice-set-editor-options {
        min-height: auto;
        padding: 10px;
        width: 100%;
        box-sizing: border-box;
        background-color: transparent !important;
        border-radius: 0;
        box-shadow: none !important;
    }

    .dice-set-editor-add-button, 
    .dice-set-editor-cancel-button,
    .dice-set-editor-delete-button {
        padding: 8px 16px;
        font-size: 12px;
    }

    .dice-display-window {
        grid-template-columns: 1fr;
        margin: 0;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        height: fit-content;
        gap: 5px;
        position: relative;
        padding-bottom: 150px;
    }

    .dice-roll-info {
        grid-column: 1;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .dice-total {
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .dice-total-title {
        font-size: 3em;
        margin: 0;
        line-height: 1;
    }

    .dice-total-subtitle {
        margin: 5px 0;
    }

    .dice-set-name {
        margin: 5px 0;
    }

    .all-dice {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        width: 100%;
        height: fit-content;
    }

    .die-container {
        margin: 5px;
        height: 70px;
        width: 70px;
        line-height: 70px;
        font-size: 2.5em;
    }

    .dice-4, .dice-6, .dice-8, .dice-10, .dice-12, .dice-20 {
        height: 70px;
        width: 70px;
    }

    .dice-4.small, .dice-6.small, .dice-8.small, .dice-10.small, .dice-12.small, .dice-20.small {
        height: 40px;
        width: 40px;
        line-height: 40px;
    }

    .dice-4 {
        line-height: 90px;
    }

    .dice-8 {
        line-height: 75px;
    }

    .dice-10 {
        line-height: 75px;
    }

    .dice-12 {
        line-height: 75px;
    }

    .dice-20 {
        line-height: 75px;
    }

    .oldDice {
        opacity: 0.4;
    }

    .dice-window > div {
        flex-direction: column;
        align-items: center;
        width: 100%;
        box-sizing: border-box;
    }

    .dice-window > div > div:first-child {
        width: 100%;
        box-sizing: border-box;
    }

    .dice-window > div > div:last-child {
        display: flex;
        justify-content: center;
        width: 100%;
        box-sizing: border-box;
    }

    .dice-action-button {
        width: 120px;
        height: 40px;
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .dice-action-button svg {
        width: 20px;
        height: 20px;
    }

    .dice-holder.selected {
        border-color: #48bb78;
    }

    .dice-set-editor-options .dice-changer .dice-holder.selected {
        border: 3px solid #f56565;
    }

    .die-container.selected,
    .die-container.editing {
        border: 3px solid #f56565;
        border-radius: 8px;
    }
}

.die-container.selected,
.die-container.editing {
    border: 3px solid #f56565;
    border-radius: 8px;
}